.top-right .Wave {
  transform: rotate(210deg);
  position: fixed;
  right: -840px;
  top: -90px;
  height: 480px;
}
.bottom-left .Wave {
  transform: rotate(30deg);
  position: fixed;
  bottom: -90px;
  left: -840px;
  height: 480px;
}

.middle-down {
  transform: scaleY(-1);
  width: 100vw;
}
.middle-up {
  width: 100vw;
}
footer svg {
  height: 240px;
}

@media (max-width: 720px) {
  .top-right .Wave {
    right: -2px;
    top: -285px;
    transform: rotate(183deg);
    height: 450px;
  }
  .bottom-left .Wave {
    transform: rotate(0deg);
    bottom: -300px;
    left: 0px;
    height: 450px;
  }
  footer svg {
    height: 600px;
  }
}

@media (max-width: 1250px) and (min-width: 720px) {
  .top-right .Wave {
    right: -301px;
    top: -186px;
  }
  .bottom-left .Wave {
    bottom: -231px;
    left: -280px;
  }
}
