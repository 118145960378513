.contact-body {
  background-color: black;
  background-image: url("../img/icon-background.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
}

#container form input {
  border-radius: 30px;
  background: #fff;
  min-width: 54%;
  min-height: 35px;
  font-family: Poppins;
  padding-left: 10px;
}

textarea {
  border-radius: 30px;
  background: #fff;
  min-width: 54%;
  min-height: 35px;
}

textarea::placeholder {
  font-family: Poppins;
}

form h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-left: 20px;
  text-align: start;
}
.contact-body p {
  text-align: start;
}

.container button {
  color: #f68634;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 47px;
  border-radius: 30px;
  border: 3px solid #d25aa3;
  background: #fcfcfc;
  min-width: 150px;
  margin-left: 20%;
}
.contact-body h5 {
  margin-left: 0px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

#message {
  height: 200px;
  padding-left: 10px;
  padding-top: 70px;
}

#contact-title {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 70px;
}

#contact-send:hover {
  border-radius: 47.5px;
  background: linear-gradient(270deg, #f68634 14.52%, #b267aa 81.41%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  transition: all 200ms ease-in-out;
}

@media (max-width: 720px) {
  input {
    width: 99%;
  }
  textarea {
    width: 99%;
  }
  .container button {
    margin-left: 30%;
  }
  .contact-body {
    background-image: none;
  }
}
