@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

:root {
  --primary-font: "Poppins", sans-serif;
}

h4 {
  color: #f6f4ee;
  font-family: var(--primary-font);
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
p {
  color: #f6f4ee;
  font-family: var(--primary-font);
  font-size: 15px;
  font-weight: 300;
  text-align: center;
}
a {
  text-decoration: none;
}
a p:hover {
  transition: all 200ms ease-in-out;
  color: #000000;
}

footer {
  height: 200px;
}
footer button {
  background-color: #f6f6f6;
  border-radius: 218px;
  height: 35px;
  width: 120px;
  font-weight: 700;
}

header .row .col-4 {
  height: 65px;
  text-align: start;
  color: white;
  font-family: var(--primary-font);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  padding: 0 0px;
}
header {
  background-color: #000000;
}
header .row {
  overflow-x: hidden;
}
footer .row .col-lg-4 {
  text-align: center;
  color: white;
  font-family: var(--primary-font);
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
footer .container {
  position: relative;
  top: -204px;
}

.title {
  width: 100vw;
  text-align: center;
  margin-top: 80px;
}
.homepage-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.form-text {
  border-radius: 218px;
  background: #f6f4ee;
  width: 300px;
  border-width: 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.form-button {
  border-radius: 218px;
  width: 120px;
  background: #c65300;
  color: #f6f4ee;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  border-width: 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
}
footer .form-button:hover {
  background: #c80a37;
  transition: all 200ms ease-in-out;
}

footer input::placeholder {
  color: #c65300;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

#homepage-body {
  background-color: black;
  width: 100vw;
  height: 100%;
}
#homepage-title {
  color: #ffffff;
  font-family: var(--primary-font);
  font-size: 45px;
  font-weight: 400;
  text-align: center;
  position: relative;
  top: -204px;
}

#burger {
  z-index: 1;
}

#experiencia {
  position: absolute;
  right: 0;
}

.bm-menu-wrap {
  background: linear-gradient(
    90deg,
    #b267aa 14%,
    #ba63a8 19%,
    #d25aa3 29%,
    #ef509d 37%,
    #f05b86 46%,
    #f37259 64%,
    #f5803e 77%,
    #f68634 86%
  );
}

.bm-cross {
  background: #bdc3c7;
}

#menu {
  position: absolute;
  top: 0;
}

.bm-item menu-item {
  color: white;
}

header #menu a {
  color: white;
}

header #menu a:visited {
  color: #c31547;
}

.bm-item-list a {
  color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 70px;
  height: 70px;
  right: 150px;
  top: 60px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  transition: all 200ms ease-in-out;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f05b86;
  transition: all 200ms ease-in-out;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

@media (max-width: 720px) {
  #homepage-title {
    font-size: 30px;
  }
  .bm-burger-button {
    position: absolute;
    width: 50px;
    height: 70px;
    right: 30px;
    top: 55px;
  }
  header h4 {
    font-size: 20px;
  }
  header .row .col-4 {
    height: 35px;
  }
  header a {
    text-align: start;
  }
  header .row {
    width: 100%;
  }
  footer .container {
    top: -560px;
  }
  footer h4 {
    font-size: 35px;
  }
  footer p {
    font-size: 20px;
  }
}
