@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

.project-body {
  background-color: rgb(255, 255, 255);
}

.project-body h1 {
  color: #2c2c2c;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.project p {
  color: #2c2c2c;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}
.zoom-animation {
  transform: scale(2) translate(20vw, 20vh);
  transition: all 200ms ease-in-out;
}

.forma {
  margin-left: -5%;
}

.project {
  margin-top: 5%;
}

.project .info {
  width: 50%;
  margin-top: 100px;
}

.ubi {
  margin-top: 10%;
  margin-bottom: 15%;
}

.ubis {
  transition: all 200ms ease-in-out;
}

.ubis:hover {
  transition: all 200ms ease-in-out;
  transform: scale(1.1);
}

#galery {
  padding-top: 200px;
}

#fotoNO {
  transition: all 200ms ease-in-out;
}

#objectius {
  color: transparent;
  font-family: Bebas Neue;
  font-size: 280px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  text-align: start;
  margin-left: 30px;
}

#obj {
  width: 100vw;
}

.objectiusnum {
  background-image: url(../img/obj-fons.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 700px;
}

.objectiusnum h5 {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  font-family: Bebas Neue;
  font-size: 180px;
  font-weight: 400;
  color: transparent;
}

.objectiusnum p {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.color {
  margin-top: 250px;
}

@media (max-width: 720px) {
  .objectiusnum h5 {
    font-size: 120px;
  }
  .project .info {
    width: 150%;
    font-size: 30px;
    margin-top: 20px;
  }
  .expo {
    margin-top: 20px;
  }
  .ubis {
    width: 100%;
  }
  #objectius {
    font-size: 110px;
  }
  .carrousel {
    position: relative;
    top: -40px;
    width: 90%;
    left: -20px;
  }
  .carrousel p {
    font-size: 25px;
  }
}
