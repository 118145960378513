@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@400;700&display=swap");

.nuria {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.nuria img {
  height: auto;
  width: 50%;
}
.team-wrapper {
  display: flex;
  flex-direction: row;
}

.carrousel {
  width: 65%;
  margin-top: 40px;
  display: flex;
  margin-left: 10%;
}

.team-body {
  overflow-x: hidden;
}

@media (max-width: 720px) {
  .team-body .carrousel {
    width: 100%;
    margin: -30px 0 0 20px;
  }
  .team-footer footer {
    position: relative;
    top: -200px;
  }
}
