#questions {
  width: 100vw;
}

.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 200ms ease-in-out;
  scale: 1.1;
}

.question-text {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 18%;
  top: 32%;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.answer-text {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 18%;
  top: 26%;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}

.down {
  margin-top: 200px;
}

@media (max-width: 720px) {
  .down {
    margin-top: 50px;
  }
}
