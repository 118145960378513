@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

:root {
  --primary-font: "Poppins", sans-serif;
}

.reservation-body .container button {
  border-radius: 30px;
  background: #f5f7ff;
  height: 40px;
  width: 10%;
  margin-left: 10px;
}

.container button:hover {
  border-radius: 47.5px;
  background: linear-gradient(270deg, #f68634 14.52%, #b267aa 81.41%);
  transition: all 200ms ease-in-out;
  color: white;
  border: none;
}

.reservation-body h4 {
  color: #fff;
  font-family: var(--primary-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: start;
}
.reservation-body h1 {
  color: #fff;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 50px;
  font-weight: 700;
  top: 0;
  margin: 60px 0 80px;
}

.reservation-body h5 {
  color: #fff;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 700;
  margin-left: 2%;
}

.reservation-body {
  background-color: black;
  width: 100vw;
  height: 100%;
  background-image: url(../img/background-line.svg);
  background-repeat: no-repeat;
  background-position: 25% 110%;
}

.reservation-body .reservation-container {
  width: 70vw;
}

main form input {
  width: 80%;
  border-radius: 30px;
  background: #fcfcfc;
  margin-bottom: 40px;
  height: 45px;
  padding-left: 15px;
}
select {
  width: 80%;
  border-radius: 30px;
  background: #fcfcfc;
  margin-bottom: 40px;
  height: 45px;
  padding-left: 15px;
}

main form input::placeholder {
  color: #111;
  font-family: var(--primary-font);
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.contact-body .container button {
  margin-left: 10px;
}
.numbers {
  width: 20%;
}

.horaris p {
  color: #fffcfc;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 15px;
  font-weight: 600;
  width: 14%;
}

.send {
  border-radius: 30px;
  background: #fcfcfc;
  width: 158px;
  height: 35px;
  border: none;
}

.send:hover {
  border-width: 5px;
}

.cbox1 {
  transition: all 200ms ease-in-out;
  color: black;
  width: 10%;
  height: 40px;
  border-radius: 30px;
  background: #f5f7ff;
  padding-top: 0.5%;
  margin: 0 2%;
  padding-left: 2%;
}

.cbox2:after {
  background: linear-gradient(270deg, #f68634 14.52%, #b267aa 81.41%);
}

@media (max-width: 720px) {
  .row {
  }
  .cbox1 {
    width: 70%;
    padding-left: 20px;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .horaris p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    width: 50%;
  }
  .col-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reservation-body .container button {
    margin: 0 auto;
    display: block;
  }
}
